@import "src/styles/helpers";

.breadcrumbs {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.24px;
    font-size: 300;

    .breadcrumb {
        @include setProperty(color, var(--secondary-50), var(--secondary-500));

        &.active {
            @include setProperty(color, var(--secondary-200), var(--secondary-300));
        }
    }

    .arrowIcon {
        transform: rotate(-90deg);
        width: 16px;
        height: 16px;

        &:last-of-type {
            display: none;
        }
    }
}