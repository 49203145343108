@import "src/styles/helpers";

.caseTeam {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    margin-top: 40px;
    margin-bottom: 40px;

    @include media(notMobile) {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .caseTeamHead {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 24px;

        .caseTeamTitle {
            font-family: 'KharkivTone', sans-serif;
            font-size: 28px;
            line-height: 36px;
            @include setProperty(color, var(--secondary-50), var(--secondary-500));

            @include media(landscape) {
                font-size: 32px;
                line-height: 40px;
            }

            @include media(desktopAll) {
                font-size: 32px;
                line-height: 40px;
            }

            mark {
                @include setProperty(color, var(--primary-500), var(--primary-900));

                span {
                    transform: none;
                }
            }
        }

        .caseTeamDescription {
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.183px;
            @include setProperty(color, var(--secondary-50), var(--secondary-500));
        }
    }
}

.membersSlider {
    position: relative;
}

.navigation {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .arrow {
        position: relative;
        z-index: 2;
        background-color: transparent;
        width: 84px;
        height: 58px;
        border-radius: 0;
        cursor: default;
        opacity: 1;

        &:disabled {
            visibility: hidden;
        }

        img {
            max-width: 84px;
            max-height: 58px;
        }
      
        &_prev {
          margin-left: -28px;

          img {
            transform: rotate(180deg);
          }
        }

        &_next {
            margin-right: -28px;
        }
    }
}