@import "src/styles/helpers";

.richTextSection {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 40px 0;

    @include media(notMobile) {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    scroll-margin: -1px;
    :global {
      .empty-paragraph {
        padding-bottom: unset !important;
      }
    }
  
    h2 {
      padding-bottom: 48px !important;
    }
  
    &__withoutPaddingTopForFirstH2 {
      &:nth-child(1) {
        padding-top: 0 !important;
      }
    }
  
    h2,
    h2 * {
      background-color: transparent !important;
      @include setProperty(color, var(--secondary-50) !important, var(--secondary-500) !important);
      font-family: 'KharkivTone', sans-serif !important;
      font-size: 32px !important;
      font-weight: 400 !important;
      line-height: 40px !important;
  
      @include media(desktopAll) {
        font-size: 40px !important;
        font-weight: 400 !important;
        line-height: 48px !important;
      }
    }
  
    h3 {
      padding-bottom: 32px !important;
    }
  
    h3,
    h3 * {
      background-color: transparent !important;
      @include setProperty(color, var(--secondary-50) !important, var(--secondary-500) !important);
      font-family: 'KharkivTone', sans-serif !important;
      font-size: 28px !important;
      font-weight: 400 !important;
      line-height: 36px !important;
  
      @include media(desktopAll) {
        font-size: 32px !important;
        font-weight: 400 !important;
        line-height: 40px !important;
      }
    }
  
    h4 {
      padding-bottom: 24px !important;
    }
  
    h4,
    h4 * {
      background-color: transparent !important;
      @include setProperty(color, var(--secondary-50) !important, var(--secondary-500) !important);
      font-family: 'KharkivTone', sans-serif !important;
      font-size: 24px !important;
      font-weight: 400 !important;
      line-height: 32px !important;
  
      @include media(desktopAll) {
        font-size: 24px !important;
        font-weight: 400 !important;
        line-height: 32px !important;
      }
    }
  
    h3, h4, h5, h6 {
      padding-top: 22px !important;
    }
  
    h3, h4, h5, h6 {
      & + h2,
      & + h3,
      & + h4,
      & + h5,
      & + h6 {
        padding-top: 0 !important;
      }
    }
  
    &__withParagraphBottomPadding {
      p {
        padding-bottom: 22px !important;
      }
  
      h2, h3, h4, h5, h6 {
        padding-top: 0 !important;
      }
    }
  
    p,
    p * {
      background-color: transparent !important;
      @include setProperty(color, var(--secondary-50) !important, var(--secondary-500) !important);
      font-family: 'Montserrat', sans-serif !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 28px !important;
  
      @include media(tablet) {
        font-size: 14px !important;
      }
  
      @include media(desktopAll) {
        font-size: 16px !important;
        line-height: 28px !important;
        letter-spacing: 0.183px !important;
      }
    }
  
    p a,
    p a * {
      text-decoration: underline !important;
      cursor: pointer !important;
      @include setProperty(color, var(--primary-500) !important, var(--primary-900) !important);
  
      strong,
      strong * {
        font-weight: 600;
        @include setProperty(color, var(--primary-500) !important, var(--primary-900) !important);
      }
  
      &:hover {
        text-decoration: none !important;
      }
    }
  
    p strong,
    p strong * {
      @include setProperty(color, var(--secondary-50) !important, var(--secondary-500) !important);
      font-weight: 600 !important;
      line-height: 32px !important;
      letter-spacing: 0.183px !important;
    }
  
    td ul li *,
    td ol li * {
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.18px;
  
      @include media(notMobile) {
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
      }
    }
  
    li ul {
      margin-top: 16px;
    }
  
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
  
      li {
        position: relative;
        padding-left: 25px;
        margin-bottom: 16px;
  
        &::before {
          content: '';
          width: 14px;
          height: 14px;
          border-radius: 50%;
          @include setProperty(background, var(--primary-500), var(--primary-900));
          position: absolute;
          left: 0;
          top: 7px;
        }
  
        &,
        span {
          @include setProperty(color, var(--secondary-50) !important, var(--secondary-500) !important);
          font-family: 'Montserrat', sans-serif !important;
          font-size: 14px !important;
          font-weight: 400 !important;
          line-height: 28px !important;
  
          strong,
          strong * {
            @include setProperty(color, var(--secondary-50) !important, var(--secondary-500) !important);
            font-weight: 600 !important;
            line-height: 32px !important;
            letter-spacing: 0.183px !important;
          }
  
          @include media(desktopAll) {
            font-size: 16px !important;
            line-height: 32px !important;
            letter-spacing: 0.183px !important;
          }
        }
      }
    }
  
    ol {
      list-style: none;
      padding: 0;
      margin: 0;
      counter-reset: list-item;
  
      li {
        position: relative;
        padding-left: 35px;
        margin-bottom: 16px;
        counter-increment: list-item;
  
        &,
        * {
          margin-bottom: 16px;
          font-family: 'Montserrat', sans-serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 24px;

          @include media(landscape) {
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.183px;
          }
  
          @include media(desktopAll) {
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.183px;
          }
        }
  
        &::before {
          content: counter(list-item);
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          font-family: 'Montserrat', sans-serif;
          font-size: 11px;
          font-weight: 400;
          line-height: 1;
          letter-spacing: 0.183px;
          position: absolute;
          left: 0;
          top: 4px;
          @include setProperty(background-color, var(--secondary-500), var(--secondary-50));
          @include setProperty(color, var(--primary-500), var(--secondary-500));
        }
  
        a {
          text-decoration: underline !important;
          cursor: pointer !important;
          @include setProperty(color, var(--primary-500) !important, var(--primary-900) !important);
  
          strong,
          strong * {
            font-weight: 600;
            @include setProperty(color, var(--primary-500) !important, var(--primary-900) !important);
          }
  
          &:hover {
            text-decoration: none !important;
          }
        }
  
        strong,
        strong * {
          font-weight: 600 !important;
          line-height: 32px !important;
          letter-spacing: 0.183px !important;
        }
  
        span {
          font-weight: 400;
          color: var(--secondary-500);
        }
      }
    }
  
    figure[class='table'] {
      width: calc(100% + var(--container-padding-mobile) + var(--container-padding-mobile));
      margin-left: calc(var(--container-padding-mobile) * -1);
      margin-right: var(--container-padding-mobile);
      overflow-x: auto;
      @include hideScrollbar;
      margin-bottom: 32px;
  
      @include media(notMobile) {
        width: 100%;
        margin-left: unset;
        margin-right: unset;
      }
    }
  
    figure[class='table'] table {
      width: 100%;
      overflow: hidden;
      border-collapse: separate;
      border-spacing: 0;
      border-radius: 8px;
      border: 1px solid;
      @include setProperty(border-color, var(--secondary-600), var(--secondary-100));
      margin-left: calc(var(--container-padding-mobile));
      margin-right: var(--container-padding-mobile);
  
      @include media(notMobile) {
        margin-left: unset;
        margin-right: unset;
      }
    }
  
    th *,
    td * {
      font-family: 'Montserrat', sans-serif !important;
      background-color: transparent !important;
    }
  
    th,
    td {
      padding: 6px 12px !important;
      text-align: left !important;
      min-width: 160px !important;
      min-height: 48px !important;
      border-top: 1px solid !important;
      border-left: 1px solid !important;
      @include setProperty(border-color, var(--secondary-500) !important, var(--secondary-100) !important);
      vertical-align: top !important;
  
      @include media(notMobile) {
        padding: 8px 16px !important;
        min-width: unset !important;
      }
    }
  
    th {
      @include setProperty(color, var(--white), var(--secondary-500));
      @include setProperty(background-color, var(--secondary-700), var(--secondary-50));
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.18px;
  
  
      @include media(notMobile) {
        font-size: 14px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0.183px;
      }
    }
  
    td,
    td p,
    td span,
    td li {
      @include setProperty(color, var(--secondary-50) !important, var(--secondary-500) !important);
      font-family: 'Montserrat', sans-serif !important;
      font-size: 12px !important;
      font-weight: 300 !important;
      line-height: 24px !important;
      letter-spacing: 0.18px !important;
  
      @include media(notMobile) {
        font-size: 14px !important;
        font-weight: 300 !important;
        line-height: 28px !important;
      }
    }
  
    td ol li::before {
      width: 18px;
      height: 18px;
  
      @include media(notMobile) {
        width: 24px;
        height: 24px;
      }
    }
  
    td:first-child,
    th:first-child {
      border-left: none !important;
    }
  
    tr:first-child th:not(tbody tr th) {
      border-top: none !important;
    }
  
    a,
    a * {
      text-decoration: underline;
      cursor: pointer;
      @include setProperty(color, var(--primary-500), var(--primary-900));
  
      strong,
      strong * {
        @include setProperty(color, var(--primary-500) !important, var(--primary-900) !important);
      }
  
      &:hover {
        text-decoration: none;
      }
    }
  
    figure[class='image'] img,
    p img {
      margin: 40px 0 0;
      width: 100%;
      height: auto;
    }
  }