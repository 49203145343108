@import "src/styles/helpers";

.modalOverlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background-color: #0000007d;
  z-index: 100;
  overflow-y: auto;

  &.blockView {
    position: static;
    background: none;
    height: auto;
  }

  @include media(landscape) {
    align-content: center;
  }

  @include media(desktopAll) {
    align-content: center;
  }
}

.contactModal {
  position: relative;
  width: 100%;
  max-width: fit-content;
  padding: 80px;
  @include setProperty(background, var(--dark-bg), var(--light-bg));
  @include hideScrollbar;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 64px;
  margin: auto;

  @include media(desktop) {
    padding: 64px;
  }

  @include media(desktopSmall) {
    padding: 48px;
    gap: 40px;
  }

  @include media(landscape) {
    max-width: 938px;
    padding: 48px;
    gap: 40px;
  }

  @include media(portrait) {
    padding: 64px 48px;
    gap: 40px;
    max-width: unset;
    min-height: 100%;
  }

  @include media(mobile) {
    padding: 64px 24px 32px;
    max-width: unset;
    min-height: 100%;
  }

  @include media(mobileSmall) {
    padding: 64px 8px 32px;
  }

  &.blockView {
    height: auto;
    padding: 64px 24px;

    @include media(portrait) {
      padding: 64px;
    }

    @include media(landscape) {
      padding: 64px 72px 120px;
      max-width: unset;
    }

    @include media(desktopAll) {
      padding: 120px 0;
    }
  }

  .mobileTitle {
    font-family: 'KharkivTone';
    position: relative;
    font-size: 28px;
    line-height: 36px;
    font-weight: 400;
    text-align: center;
    max-width: 260px;
    align-self: center;
    
    mark {
      @include setProperty(color, var(--primary-500), var(--primary-900));
    }

    @include media(notMobile) {
      display: none;
    }

    @include media(portrait) {
      display: block;
    }
  }

  .modalBody {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 40px;

    @include media(landscape) {
      flex-direction: row;
      justify-content: center;
      gap: 56px;
    }

    @include media(desktopAll) {
      flex-direction: row;
      justify-content: center;
      gap: 92px;
    }

    h1 {
      margin: 0;
      font-size: 36px;
      line-height: 44px;
      max-width: 300px;

      @include media(desktopAll) {
        font-size: 60px;
        line-height: 68px;
        max-width: 500px;
      }
    }

    h3 { 
      margin: 0;
      font-size: 28px;
      line-height: 36px;
      max-width: 250px;

      @include media(landscape) {
        font-size: 32px;
        line-height: 40px;
        max-width: 300px;
      }

      @include media(desktopAll) {
        font-size: 32px;
        line-height: 40px;
        max-width: 300px;
      }
    }

    .expertWrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .contactExpert {
      position: relative;
      display: flex;
      width: 100%;
      overflow: hidden;

      @include media(landscape) {
        flex-direction: column;
        width: 393px;
        height: 564px;
      }

      @include media(desktopAll) {
        flex-direction: column;
        width: 434px;
        height: 568px;
      }

      @include media(portrait) {
        justify-content: flex-end;
      }

      .expertAvatar {
        display: none;

        @include media(mobile){
          display: block;
          align-self: start;
          max-width: 85px;
          height: auto !important;
          position: relative !important;
        }
         @include media(portrait) {
          display: block;
          align-self: start;
          max-width: 187px;
          height: auto !important;
        }

        &.mobile {
          @include media(notMobile) {
            display: none;
          }
        }

        &.desktop {
          @include media(mobile) {
            display: none;
          }
        }
      }

      .expertInfoWrapper {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        flex: 1 0 0;

        @include media(mobile) {
          background-size: 0;
        }

        @include media(portrait) {
          max-width: calc(100% - 187px);
        }
      }

      .expertInfo {
        bottom: 0;
        height: 100%;
        backdrop-filter: blur(12px);
        padding: 0 0 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        background-size: cover;
        background-repeat: no-repeat;
        @include setProperty(background, (linear-gradient(90deg, rgba(246, 255, 254, 0.00) -33.37%, rgba(155, 188, 183, 0.04) 35.32%, rgba(246, 255, 254, 0.00) 118.57%), rgba(20, 20, 20, 0.90)), (linear-gradient(90deg, rgba(246, 255, 254, 0.00) -33.37%, rgba(155, 188, 183, 0.04) 35.32%, rgba(246, 255, 254, 0.00) 118.57%), rgba(246, 246, 246, 0.90)));
        
        @include media(portrait) {
          padding: 24px;
        }

        @include media(landscape) {
          padding: 32px;
          gap: 32px;
          height: auto;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
        }

        @include media(desktopAll) {
          padding: 32px;
          gap: 32px;
          height: auto;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
        }

        .expertDescriptionWrapper {
          display: flex;

          @include media(mobile) {
            max-height: 104px;
            overflow: hidden;
          }
        }

        .expertDescription {
          @include media(mobile) {
            flex: 1 0 0;
            padding: 16px 16px 0;
            gap: 16px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
          }

          @include media(mobile) {
            padding: 16px 8px 0;
          }
    
          @include media(landscape) {
            text-align: center;
          }

          @include media(desktopAll) {
            text-align: center;
          }

          .title {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
          }
        }

        .actions {
          display: flex;
          align-items: center;
          gap: 16px;

          button {
            @include media(mobile) {
              padding: 12px 24px;
              height: 36px;
              font-size: 14px;
              line-height: 14px;
            }

            @include media(mobileSmall) {
              padding: 10px 8px 7px;
              height: 36px;
              font-size: 12px;
            }
          }

          @include media(mobile) {
            gap: 12px;
          }

          &.mobile {
            @include media(notMobile) {
              display: none;
            }
          }

          &.desktop {
            @include media(mobile) {
              display: none;
            }
          }
        }
      
      }
    }

    .expertDescriptionText {
      font-size: 12px;
      line-height: 28px;
      letter-spacing: 0.18px;
      font-weight: 300;
      text-align: center;

      @include media(portrait) {
        text-align: left;
      }

      @include media(desktopAll) {
        font-size: 14px;
        line-height: 28px;
      }

      @include media(mobile) {
        padding: 0 8px;
        text-align: left;
      }
    }
  }

  .socials {
    display: flex;
    gap: 16px;

    &.mobile {
      flex-direction: column;

      @include media(notMobile) {
        display: none;
      }

      @include media(portrait) {
        display: flex;
      }
    }

    &.desktop {
      display: none;

      @include media(notMobile) {
        display: flex;
        position: absolute;
        top: 12px;
        left: 12px;
      }
      
      @include media(portrait) {
        display: none;
      }
    }
  
    .socialLink {
      @include setProperty(background, linear-gradient(267deg, #9BFFF0 0%, rgba(246, 255, 254, 0.18) 100%), none);
      @include setProperty(border, none, 1px solid var(--secondary-500));

      @include media(mobile) {
        max-width: 36px;
        max-height: 36px;
      }
      
      a {
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        @include setProperty(background, var(--secondary-500), rgba(246, 246, 246, 0.50));
      }

      svg {
        @include setProperty(color, var(--secondary-50), var(--secondary-500));
      }
    }
  }

  .modalFooter {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .submitSteps {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      flex: 1 0 0;
      gap: 16px;

      @include media(landscape) {
        flex-direction: row;
        gap: 56px;
      }

      @include media(desktopAll) {
        flex-direction: row;
        gap: 56px;
      }

      .submitStep {
        display: flex;
        gap: 16px;
        flex: 1 0 0;

        @include media(portrait) {
          gap: 12px;
        }

        .number {
          width: 40px;
          height: 40px;
          border-radius: 56px;
          border: 1px solid var(--dark-line-accent);
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .stepDescription {
          font-size: 14px;
          line-height: 28px;
          letter-spacing: 0.183px;
          font-weight: 300;
          @include setProperty(color, var(--secondary-50), var(--secondary-500));
          flex: 1 0 0;

          @include media(notMobile) {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          @include media(portrait) {
            display: block;
          }

          @include media(landscape) {
            flex-direction: column;
            align-items: flex-start;
            gap: 0;
          }

          @include media(desktopAll) {
            font-size: 16px;
            line-height: 28px;
            flex-direction: column;
            align-items: flex-start;
            gap: 0;
          }

          b {
            font-weight: 500;
          }
        }
      }
    }
  }

  .formWrapper {
    width: 100%;
    padding: 0;

    @include media(landscape) {
      max-width: 560px;
    }

    @include media(desktopAll) {
      max-width: 560px;
    }

    .contactTitle {
      @include media(mobile) {
        display: none;
      }
      
      @include media(portrait) {
        display: none;
      }
    }

    & > :last-child {
      padding: 0 !important;
      width: auto;

      gap: 40px;

      @include media(portrait) {
        gap: 32px;
      }

      @include media(landscape) {
        gap: 36px;
      }

      @include media(desktopAll) {
        gap: 56px
      }

      @include media(desktopSmall) {
        gap: 42px;
      }
    }

    .customForm {
      margin: 0;
      gap: 40px;

      @include media(portrait) {
        gap: 32px;

       & > :nth-child(n) {
          grid-column: auto/ span 4;
        }
  
        & > :nth-child(1),
        & > :nth-child(2) {
          grid-column: auto/ span 2;
        } 
      }

      @include media(landscape) {
        gap: 36px;
      }

      @include media(desktopAll) {
        gap: 56px
      }

      @include media(desktopSmall) {
        gap: 42px;
      }

      :global {
        .MuiInput-root {
          margin-top: 0;
        }

        .MuiInputLabel-root {
          top: -16px;
        }

        textarea.MuiInput-input {

          &:focus {
            margin-top: 4px;
          }

          @include media(mobile) {
            max-height: 40px;
          }

          @include media(portrait) {
            max-height: 40px;
          }
        }
      }
      
      footer {
        margin-top: 0;

        @include media(portrait) {
          margin-top: -8px;
        }

        @include media(landscape) {
          margin-top: 12px;
        }

        @include media(desktopAll) {
          margin-top: -8px;
        }

        @include media(desktopSmall) {
          margin-top: 6px;
        }

        button {
          justify-self: end;

          @include media(notMobile) {
            max-width: 196px;
          }
        }
      }
    }
  }

  .oldFormWrapper {

    @include media(mobile) {
      padding: 40px 0;
    }

    @include media(portrait) {
      padding: 60px 0;
    }

    @include media(landscape) {
      padding: 60px 0;
    }
  }
}

.mobileOnly {
  @include media(notMobile) {
    display: none;
  }
}

.mobileHide {
  @include media(mobile) {
    display: none;
  }
}

.closeButton {
  position: absolute;
  z-index: 3;
  right: var(--margin-md);
  top: var(--margin-md);
  width: 40px;
  height: 24px;
  @include setProperty(color, var(--secondary-200), var(--secondary-200));

  &:hover {
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
  }

  svg {
    width: 100%;
    height: 100%;
  }
}